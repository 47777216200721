<template>
  <div>
    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">
<!--              <div class="form-group col-md-6 mt-2 mb-2">-->
<!--                <label>{{ $t('product_purchase_report.supplier') }}</label>-->
<!--                <multiselect v-model="supplier"-->
<!--                             :placeholder="$t('product_purchase_report.supplier')"-->
<!--                             label="full_name"-->
<!--                             track-by="id"-->
<!--                             :options="suppliers"-->
<!--                             :multiple="false"-->
<!--                             :taggable="false"-->
<!--                             :show-labels="false"-->
<!--                             :show-no-options="false"-->
<!--                             :show-no-results="false"-->
<!--                             @search-change="getSuppliers($event)">-->
<!--                </multiselect>-->
<!--              </div>-->

<!--              <div class="form-group col-md-6 mt-2 mb-2">-->
<!--                <label>{{ $t('product_purchase_report.item') }}</label>-->
<!--                <multiselect v-model="item" :placeholder="$t('product_purchase_report.item')"-->
<!--                             label="name"-->
<!--                             track-by="id"-->
<!--                             :options="items"-->
<!--                             :multiple="false"-->
<!--                             :taggable="false"-->
<!--                             :show-labels="false"-->
<!--                             :show-no-options="false"-->
<!--                             :show-no-results="false"-->
<!--                             @search-change="getItems('name', $event)">-->
<!--                </multiselect>-->
<!--              </div>-->
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="suppliers_ids">{{ $t('purchase_report.suppliers') }}</label>
                <multiselect id="suppliers_ids"
                             v-model="suppliers_ids"
                             :placeholder="$t('purchase_report.suppliers')"
                             label="full_name"
                             track-by="id"
                             :options="suppliers"
                             :multiple="true"
                             @input="getIds('suppliers')"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getSuppliers($event)">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="item_id">{{ $t('profit_of_sales_item_report.items') }}</label>
                <div class="d-flex search-item-group">
                  <select v-model="f_by" class="custom-select" name="" style="width: 100px;">
                    <option selected value="name">{{ $t('items.name') }}</option>
                    <option value="sku_code">{{ $t('items.sku_code') }}</option>
                  </select>
                  <multiselect id="item_id"
                               v-model="items_ids"
                               :internal-search="false"
                               :multiple="true"
                               :options="items"
                               :label="f_by?f_by:'sku_code'"
                               @input="getIds('items')"
                               :placeholder="$t('profit_of_sales_item_report.items')"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               :taggable="false"
                               track-by="id"
                               @search-change="getItems(f_by, $event)">
                  </multiselect>
                </div>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('purchase_report.added_by') }}</label>
                <multiselect id="user_id"
                             v-model="user"
                             :placeholder="$t('purchase_report.added_by')"
                             label="name"
                             track-by="id"
                             :options="users"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getUsers($event)">
                </multiselect>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('order_by') }}</label>
                <select name="" id="order_by" v-model="filters.order_by" class="custom-select">
                  <option value="">{{ $t('all') }}</option>
                  <option value="asc">{{ $t('asc') }}</option>
                  <option value="desc">{{ $t('desc') }}</option>
                </select>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('product_purchase_report.currency') }}</label>
                <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select">
                  <option value="">{{ $t('all') }}</option>
                  <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                </select>
              </div>


              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('product_purchase_report.from_date') }}</label>
                <input type="date" id="from_date" v-model="filters.from_date" class="form-control">
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('product_purchase_report.to_date') }}</label>
                <input type="date" id="to_date" v-model="filters.to_date" class="form-control">
              </div>

              <div class="col-md-6 mt-2 mb-2 pt-10">
                <div>
                  <b-button-group>
                    <b-button  @click="changeIntervalType('current_day')" :variant="filters.period=='current_day'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_date')}}</b-button>
                    <b-button  @click="changeIntervalType('current_month')" :variant="filters.period=='current_month'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_month')}}</b-button>
                    <b-button  @click="changeIntervalType('current_year')" :variant="filters.period=='current_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_year')}}</b-button>
                    <b-button  @click="changeIntervalType('financial_year')" :variant="filters.period=='financial_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>
                    <!--                    <b-button  @click="changeFinancialYear" :variant="filters.financial_year==1? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>-->
                  </b-button-group>

                </div>
                <div class="mt-5">
                  <b-button-group>
                    <b-button  @click="changeIntervalType('yesterday')" :variant="filters.period=='yesterday'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.yesterday')}}</b-button>
                    <b-button  @click="changeIntervalType('last_month')" :variant="filters.period=='last_month'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.last_month')}}</b-button>
                    <b-button  @click="changeIntervalType('last_year')" :variant="filters.period=='last_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.last_year')}}</b-button>
                  </b-button-group>

                </div>
              </div>

<!--              <div class="form-group col-md-6 mt-2 mb-2">-->
<!--                <label>{{ $t('group_by') }}</label>-->
<!--                <select name="" id="" v-model="filters.group_by" class="custom-select">-->
<!--                  <option value="product">{{ $t('product_purchase_report.product') }}</option>-->
<!--                  <option value="supplier">{{ $t('product_purchase_report.supplier') }}</option>-->
<!--                  <option value="user">{{ $t('product_purchase_report.user') }}</option>-->
<!--                </select>-->
<!--              </div>-->


              <div class="form-group d-inline-flex col-md-6 mt-10">
                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                  {{ $t('display_report') }}
                </button>
                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>


    <div class="card card-custom">
      <div class="card-body">
        <b-tabs content-class="mt-3">
          <b-tab :title="$t('summery')" :active="tab == 'summery' || tab == null">
            <div class="col-md-12 mb-5">
              <div class="m-form m-form--fit m--margin-bottom-20">
                <div class="row">
                  <div class="col-12 mt-10" :id="'summaryTable'+filters.group_by+'Title'">
                    <!-- Clients Installments - Group By Client -->
                    <h4 class="text-center">{{ $t('product_purchase_report.product_purchase_report') }}</h4>
                    <h5 class="text-center"><span v-if="filters.from_date">{{ $t('from') }}
                                    {{ filters.from_date }}</span> <span v-if="filters.to_date">{{ $t('to') }} {{ filters.to_date }}</span>
                    </h5>
                  </div>
                  <div class="col-12 mt-10 d-flex justify-content-end">
                    <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                      <button class="dropdown-item" @click="convertTableToExcelReport('summaryTable'+filters.group_by)">
                        <i class="fa fa-file-excel"></i>{{ $t('excel') }}
                      </button>
                      <button class="dropdown-item" @click="printData('summaryTable'+filters.group_by, 'print')">
                        <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                      </button>
<!--                      <button class="dropdown-item" @click="printPDFData('summaryTable'+filters.group_by, 'pdf')">-->
<!--                        <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>-->
<!--                      </button>-->
                      <button class="dropdown-item" @click="getAllDataForExportSummery('sectionForExportSummary', 'excel')">
                        <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                      </button>
                    </b-dropdown>
                  </div>
<!--                  <div class="col-12 mt-10" v-if="dataChartList">-->
<!--                    <bar-chart :data-list="dataChartList" :currency-name="currency_name"></bar-chart>-->
<!--                  </div>-->
                  <div class="col-12 mt-10">
                    <div class="table-responsive">
                      <table class="table table-bordered" :id="'summaryTable'+filters.group_by">
                        <thead>

                        <tr id="myRow4">
                          <th>{{ $t('product_purchase_report.' + filters.group_by) }}</th>
                          <th>{{ $t('product_purchase_report.quantity') }}</th>
                          <th>{{ $t('product_purchase_report.total') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row2, index2) in dataSummary" :key="'z'+getRandom()+index2">
                          <td>{{ row2.label }}</td>
                          <td>{{ row2.total_quantity }}</td>
                          <td>{{ row2.total_total }}</td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr v-if="dataTotalSummary">
                          <td><b>{{ $t('product_purchase_report.total') }}</b></td>
                          <th>{{ dataTotalSummary.sum_total_quantity }}</th>
                          <th>{{ dataTotalSummary.sum_total_total }}</th>
                        </tr>
                        </tfoot>
                      </table>

                    </div>

                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-5 text-center">
                  <button class="btn btn-warning" @click="loadMoreSummary">
                    <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                    {{ $t('load_more') }}
                  </button>
                </div>
              </div>

            </div>
          </b-tab>

          <b-tab :title="$t('details')" :active="tab == 'details'">
            <div class="col-md-12 mb-5">
              <div class="m-form m-form--fit m--margin-bottom-20">
                <div class="row">
                  <div class="col-12 mt-10" :id="'summaryTableDetails'+filters.group_by+'Title'">
                    <!-- Clients Installments - Group By Client -->
                    <h4 class="text-center">{{ $t('product_purchase_report.product_purchase_report') }}</h4>
                    <h5 class="text-center"><span v-if="filters.from_date">{{ $t('from') }}
                                    {{ filters.from_date }}</span> <span v-if="filters.to_date">{{ $t('to') }} {{ filters.to_date }}</span>
                    </h5>
                  </div>
                  <div class="col-12 mt-10 d-flex justify-content-end">
                    <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                      <button class="dropdown-item" @click="convertTableToExcelReport('summaryTableDetails'+filters.group_by)">
                        <i class="fa fa-file-excel"></i>{{ $t('excel') }}
                      </button>
                      <button class="dropdown-item" @click="printData('summaryTableDetails'+filters.group_by, 'print')">
                        <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                      </button>
<!--                      <button class="dropdown-item" @click="printPDFData('summaryTableDetails'+filters.group_by, 'pdf')">-->
<!--                        <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>-->
<!--                      </button>-->
                      <button class="dropdown-item" @click="getAllDataForExport('sectionForExport', 'excel')">
                        <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                      </button>
                    </b-dropdown>
                  </div>
<!--                  <div class="col-12 mt-10" v-if="dataChartList">-->
<!--                    <bar-chart :data-list="dataChartList" :currency-name="currency_name"></bar-chart>-->
<!--                  </div>-->
                  <div class="col-12 mt-10">
                    <div class="table-responsive">
                      <table class="table table-bordered" :id="'summaryTableDetails'+filters.group_by">
                        <thead>
                        <tr id="myRow">
                          <th>#</th>
                          <th>{{ $t('product_purchase_report.id') }}</th>
                          <th>{{ $t('product_purchase_report.name') }}</th>
                          <th>{{ $t('product_purchase_report.product_code') }}</th>
                          <th>{{ $t('product_purchase_report.date') }}</th>
                          <th>{{ $t('product_purchase_report.invoice_type') }}</th>
                          <th>{{ $t('product_purchase_report.invoice_code') }}</th>
                          <th>{{ $t('product_purchase_report.user') }}</th>
                          <th>{{ $t('product_purchase_report.supplier') }}</th>
                          <th>{{ $t('product_purchase_report.unit_price') }}</th>
                          <th>{{ $t('product_purchase_report.total_taxes') }}</th>
                          <th>{{ $t('product_purchase_report.quantity') }}</th>
                          <th>{{ $t('product_purchase_report.total') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(row, index) in data">
                          <tr :key="'c'+index">
                            <td colspan="13">{{ row.label }}</td>
                          </tr>
                          <tr v-for="(row2, index2) in row.items" :key="'c'+getRandom()+index2">
                            <td>{{ index2 + 1 }}</td>
                            <td>{{ row2.item_id }}</td>
                            <td>{{ row2.item_name }}</td>
                            <td>{{ row2.product_code }}</td>
                            <td>{{ row2.date }}</td>
                            <td>{{ row2.type }}</td>
                            <td>{{ row2.invoice_code }}</td>
                            <td>{{ row2.added_by }}</td>
                            <td>{{ row2.supplier_name }}</td>
                            <td>{{ row2.price_per_unit }}</td>
                            <td>{{ row2.total_taxes }}</td>
                            <td>{{ row2.qty }}</td>
                            <td>{{ row2.total }}</td>
                          </tr>
                          <tr :key="'d'+index">
                            <td colspan="11">{{ $t('product_purchase_report.total') }}</td>
                            <td>{{ row.total_quantity? row.total_quantity: 0 }}</td>
                            <td>{{ row.total_total? row.total_total: 0 }}</td>
                          </tr>
                        </template>

                        </tbody>
                        <tfoot>
                        <tr v-if="dataTotal">
                          <td colspan="11"><b>{{ $t('product_purchase_report.total') }}</b></td>
                          <th>{{ dataTotal.sum_total_quantity }}</th>
                          <th>{{ dataTotal.sum_total_total }}</th>
                        </tr>
                        </tfoot>
                      </table>

                    </div>

                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 mt-5 text-center">
                  <button class="btn btn-warning" @click="loadMore">
                    <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                    {{ $t('load_more') }}
                  </button>
                </div>
              </div>


            </div>
          </b-tab>
          <template #tabs-end>
            <li class="nav-item  d-flex my-auto">
              <select name="" id="" v-model="filters.group_by" @input="onGroupByChanged" class="custom-select border-0 pt-0 pb-0 h-auto shadow-none">
                <option :value="null" disabled>{{ $t('select_filter') }}</option>
                <option value="day">{{ $t('daily') }}</option>
                <option value="week">{{ $t('weekly') }}</option>
                <option value="month">{{ $t('monthly') }}</option>
                <option value="year">{{ $t('yearly') }}</option>
                <option value="product">{{ $t('product_purchase_report.product') }}</option>
                <option value="supplier">{{ $t('product_purchase_report.supplier') }}</option>
                <option value="user">{{ $t('product_purchase_report.user') }}</option>
              </select>

            </li>
          </template>

        </b-tabs>
      </div>
    </div>
    <div class="selector-export" id="selectorExport"></div>
    <!--end::customer-->
    <div class="export-container">
      <div class="section-for-export">
        <table class="table table-bordered" id="sectionForExportSummary">
          <thead>

          <tr>
            <th>{{ $t('product_purchase_report.' + filters.group_by) }}</th>
            <th>{{ $t('product_purchase_report.quantity') }}</th>
            <th>{{ $t('product_purchase_report.total') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row2, index2) in dataSummaryList" :key="'zee'+getRandom()+index2">
            <td>{{ row2.label }}</td>
            <td>{{ row2.total_quantity }}</td>
            <td>{{ row2.total_total }}</td>
          </tr>
          </tbody>
          <tfoot>
          <tr v-if="totalsSummaryList">
            <td><b>{{ $t('product_purchase_report.total') }}</b></td>
            <th>{{ totalsSummaryList.sum_total_quantity }}</th>
            <th>{{ totalsSummaryList.sum_total_total }}</th>
          </tr>
          </tfoot>
        </table>

        <table class="table table-bordered" id="sectionForExport">
          <thead>
          <tr>
            <th>#</th>
            <th>{{ $t('product_purchase_report.id') }}</th>
            <th>{{ $t('product_purchase_report.name') }}</th>
            <th>{{ $t('product_purchase_report.product_code') }}</th>
            <th>{{ $t('product_purchase_report.date') }}</th>
            <th>{{ $t('product_purchase_report.invoice_type') }}</th>
            <th>{{ $t('product_purchase_report.invoice_code') }}</th>
            <th>{{ $t('product_purchase_report.user') }}</th>
            <th>{{ $t('product_purchase_report.supplier') }}</th>
            <th>{{ $t('product_purchase_report.unit_price') }}</th>
            <th>{{ $t('product_purchase_report.total_taxes') }}</th>
            <th>{{ $t('product_purchase_report.quantity') }}</th>
            <th>{{ $t('product_purchase_report.total') }}</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(row, index) in dataDetailsList">
            <tr>
              <td colspan="13">{{ row.label }}</td>
            </tr>
            <tr v-for="(row2, index2) in row.items" :key="index+getRandom()+index2">
              <td>{{ index2 + 1 }}</td>
              <td>{{ row2.item_id }}</td>
              <td>{{ row2.item_name }}</td>
              <td>{{ row2.product_code }}</td>
              <td>{{ row2.date }}</td>
              <td>{{ row2.type }}</td>
              <td>{{ row2.invoice_code }}</td>
              <td>{{ row2.added_by }}</td>
              <td>{{ row2.supplier_name }}</td>
              <td>{{ row2.price_per_unit }}</td>
              <td>{{ row2.total_taxes }}</td>
              <td>{{ row2.qty }}</td>
              <td>{{ row2.total }}</td>
            </tr>
            <tr>
              <td colspan="11">{{ $t('product_purchase_report.total') }}</td>
              <td>{{ row.total_quantity? row.total_quantity: 0 }}</td>
              <td>{{ row.total_total? row.total_total: 0 }}</td>
            </tr>
          </template>

          </tbody>
          <tfoot>
          <tr v-if="totalsDetailsList">
            <td colspan="11"><b>{{ $t('product_purchase_report.total') }}</b></td>
            <th>{{ totalsDetailsList.sum_total_quantity }}</th>
            <th>{{ totalsDetailsList.sum_total_total }}</th>
          </tr>
          </tfoot>
        </table>

      </div>
    </div>

    <div class="export-container">
      <div class="section-for-export" id="sectionForExportHeader">
        <!--        <report-main-header :print-type="'ProductPurchaseReport'"></report-main-header>-->
      </div>
    </div>

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import TableToExcel from "@linways/table-to-excel";
import BarChart from "./components/BarChart";
import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";

export default {
  name: "index-product-purchase-report",
  components: {'bar-chart': BarChart},

  data() {
    return {
      mainRoute: '/reports/purchases/products',
      mainRouteDependency: 'base/dependency',

      data: [],
      // data_summary: [],
      dataTotal: [],

      dataDetailsList: [],
      totalsDetailsList: [],

      dataSummary: [],
      dataTotalSummary: [],

      dataSummaryList: [],
      totalsSummaryList: [],

      dataChartList: null,
      suppliers: [],
      currencies: [],
      items: [],
      users: [],
      user: null,

      data_more_details: [],
      details_total_sum: [],


      filters: {
        f_items_ids:[],
        f_suppliers_ids:[],

        supplier_id: null,
        item_id: null,

        group_by: null,
        from_date: null,
        to_date: null,
        order_by: null,
        currency_id: null,
        user_id: null,

        period: null,
        range: null,
        f_time_period: 0,
        financial_year: 0,
      },
      currency_name: null,
      supplier: null,
      item: null,
      tab: this.$route.query.tab ? this.$route.query.tab : null,
      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page: 1,
      page_summary: 1,
      items_ids:[],
      suppliers_ids:[],
      f_by: 'name',
    }
  },
  watch: {
    // supplier: function (val) {
    //   if (val) {
    //     this.filters.supplier_id = val.id;
    //   } else {
    //     this.filters.supplier_id = null;
    //   }
    // },
    // item: function (val) {
    //   if (val) {
    //     this.filters.item_id = val.id;
    //   } else {
    //     this.filters.item_id = null;
    //   }
    // },
    // 'filters.group_by': function () {
    //   this.data = [];
    //   this.dataTotal = [];
    // },
    user: function (val) {
      if (val) {
        this.filters.user_id = val.id;
      } else {
        this.filters.user_id = null;
      }
    },
  },
  methods: {
    ...cssStypeForPrintReport,

    loadMore() {
      this.page = this.page + 1;
      this.getData();
      // this.getDataChartList();
      // this.getDataSummary();
    },
    loadMoreSummary() {
      this.page_summary = this.page_summary + 1;
      this.getDataSummary();
    },

    convertTableToExcelReport(tableId) {
      this.setReportLog('excel', 'product purchase report');
      TableToExcel.convert(document.getElementById(tableId), {
        name: this.$t('product_purchase_report.product_purchase_report') + '.xlsx',
        sheet: {
          name: 'Sheet 1'
        }
      });
    },


    getRandom() {
      return Math.floor(Math.random() * 10000000000);
    },

    async getAuthUser() {
      await ApiService.get(`/get_auth`).then((response) => {
        // this.user = response.data.data;
        this.currency_name = response.data.data.currency_name;
        // if (this.user) {
        //   this.user_name = this.user.name;
        // }
      });
    },

    // selectFilter() {
    //     this.getData();
    // },
    changeIntervalType(action_type, range = null){
      if (this.filters.period == action_type){
        this.filters.period = null;
        this.filters.f_time_period = 0;
      }else{
        this.filters.period = action_type;
        this.filters.f_time_period = 1;
      }

      // this.filters.range = range;
      // if (range == 1){
      //   this.inner_period = 'last_'+action_type;
      //   if (action_type == 'year')
      //     this.filters.range = this.$moment().subtract(1, 'year').year();
      // }else{
      //   this.inner_period = action_type;
      // }


      // this.period_counter = [];
      // if (this.filters.period){
      //   let _counter = this.period_numbers[this.filters.period];
      //   if (this.filters.period == 'year') {
      //     let _current_year = new Date().getFullYear();
      //     this.period_counter.push(parseInt(_current_year));
      //     for (let cc = 1; cc <= _counter; cc++){
      //       this.period_counter.push(parseInt(_current_year) - cc);
      //     }
      //   }else {
      //     for (let cc = 1; cc <= _counter; cc++){
      //       this.period_counter.push(cc);
      //     }
      //   }
      //
      // }else {
      //   this.period_counter = [];
      // }

    },
    doFilter() {
      this.page = 1;
      this.page_summary = 1;
      this.getData();
      // this.getDataChartList();
      this.getDataSummary();
    },
    getIds(related_with){
      switch (related_with) {
        case 'suppliers':
          this.filters.f_suppliers_ids = this.suppliers_ids.map((row) => row.id);
          break;
        case 'items':
          this.filters.f_items_ids = this.items_ids.map((row) => row.id);
          break;
      }

    },
    resetFilter() {
      this.suppliers_ids = null;
      this.items_ids = null;

      this.filters.f_suppliers_ids = null;
      this.filters.f_items_ids = null;
      // this.filters.group_by = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.order_by = null;

      this.user = null;
      this.filters.user_id = null;
      this.filters.currency_id = null;

      this.filters.period = null;
      this.filters.range = null;
      this.filters.f_time_period = 0;
      this.filters.financial_year = 0;

      this.doFilter();
      // this.getDataChartList();
      // this.getDataSummary();


    },
    onGroupByChanged: _.debounce(function (){
      this.doFilter();
    }, 100),
    dataReport() {
      return this.data;
    },

    // getDataChartList() {
    //   ApiService.get(this.mainRoute, {params: {...this.filters, page: (this.page ? this.page : 1), dataType: 'chart'}}).then((response) => {
    //     this.dataChartList = response.data.data;
    //   });
    // },

    getData() {
      let _limit = (this.page ? this.page : 1) * 15;
      ApiService.get(this.mainRoute, {params: {...this.filters, limit: _limit}}).then((response) => {
        this.data = response.data.data.data;
        this.dataTotal = response.data.data.totals;
      });
    },

    getDataSummary() {
      let _limit = (this.page_summary ? this.page_summary : 1) * 15;
      ApiService.get(`/v2${this.mainRoute}/summary`, {params: {...this.filters, limit: _limit}}).then((response) => {
        this.dataSummary = response.data.data.data;
        this.dataTotalSummary = response.data.data.totals;
      });
    },
    getAllDataForExport(tableId, _export) {
      ApiService.get(this.mainRoute, {params: {...this.filters, is_export: 1}}).then((response) => {
        this.dataDetailsList = response.data.data.data;
        this.totalsDetailsList = response.data.data.totals;
        setTimeout(() => {
        this.convertTableToExcelReport(tableId);
        }, 100)
      });
    },
    getAllDataForExportSummery(tableId, _export) {
      ApiService.get(`/v2${this.mainRoute}/summary`, {params: {...this.filters, is_export: 1}}).then((response) => {
        this.dataSummaryList = response.data.data.data;
        this.totalsSummaryList = response.data.data.totals;
        setTimeout(() => {
        this.convertTableToExcelReport(tableId);
        }, 100)
      });
    },
    // getDataSummary() {
    //   ApiService.get(this.mainRoute, {params: {...this.filters, page: (this.page ? this.page : 1), dataType: 'list'}}).then((response) => {
    //     this.data_summary = response.data.data;
    //     this.dataTotalSummary = response.data.total;
    //   });
    // },


    async getSuppliers(filter) {
      if (filter && filter.length >= 3)
        await ApiService.get(this.mainRouteDependency + "/suppliers", {params: {filter: filter}}).then((response) => {
          this.suppliers = response.data.data;
        });
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },
    getUsers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/users", {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
    },
    printData(tableId) {
      this.setReportLog('pdf', 'product purchase report');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },
    printPDFData(tableId, type) {
      this.setReportLog('pdf', 'product purchase report');
      // let divToPrint = document.getElementById(tableId);
      // let divTitleToPrint = document.getElementById(tableId + 'Title');
      // let newWin = window.open("");
      // newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
      // setTimeout(() => {
      //     newWin.print();
      //     newWin.close();
      // }, 100)

      exportPDFInnerAction(tableId, type, this.$t('product_purchase_report.product_purchase_report'), this.$t('product_purchase_report.product_purchase_report'));

    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.purchases_reports'), route: '/reports/purchase-reports-links'}, {title: this.$t("MENU.product_purchase_report")}]);
    this.filters.group_by = this.$route.query.group_by ? this.$route.query.group_by : 'product';

    this.getCurrencies();
    this.getItems();
    this.getAuthUser();

  },
};
</script>
<style>
.html2pdf__overlay {
  opacity: 0 !important;
  z-index: -1000;

}

.selector-export {
  opacity: 0 !important;
  z-index: -1000 !important;
  width: 0.0001px !important;
  height: 0.0001px !important;
  overflow: auto;
}

</style>