import i18n from "@/core/plugins/vue-i18n";
let getCssStyleForPrintReport = () =>{
    let _lang = i18n.locale;
    return 'body {\n' +
        'direction: ' + (_lang == 'ar'? 'rtl': 'ltr')+'!important;\n' +
        'text-align: start;\n'+
        '}\n' +
        '.title-1 {\n' +
        '  font-size: 21px;\n' +
        '  color: #1a5a4a;\n' +
        '}\n' +
        '.title-1-size-20 {\n' +
        '  font-size: 20px;\n' +
        '}\n' +
        '.logo-w {\n' +
        '  width: 100px;\n' +
        '  margin-left: 0;\n' +
        '}\n' +
        '.title-2 {\n' +
        '  font-size: 20px;\n' +
        '  color: #000;\n' +
        '  padding-top: 8px;\n' +
        '}\n' +
        '.watermark {\n' +
        '  position: absolute;\n' +
        '  top: 0;\n' +
        '  bottom: 0;\n' +
        '  opacity: 0.08;\n' +
        '  z-index: 0;\n' +
        '  color: white;\n' +
        '  width: 579px;\n' +
        '  left: 0;\n' +
        '  right: 0;\n' +
        '  margin: auto;\n' +
        '}\n' +
        ' .end-bottom{\n' +
        '   margin-bottom: auto;\n' +
        '   margin-top: auto;\n' +
        '   position: absolute;\n' +
        '   bottom: 0;\n' +
        '   left: 0;\n' +
        '   right: 0;\n' +
        ' }'
}

export default {getCssStyleForPrintReport};